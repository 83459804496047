<template>
    <div v-if="!loading">
        <select-shop v-show="!template.type" :loadingButton="!loadingButton" :backButton="true"></select-shop>
        <div v-if="isPermission">
            <CCard class="shadow-sm">
                <CCardBody class="p-4">
                    <div v-if="!template.type">
                        <div class="row ">
                            <div class="col-md-12 col-sm-12 ">
                                <div class="pl-2 pr-5">
                                    <h3>{{ $t('templatePromotion') }}</h3>
                                    <span style="color: gray; ">
                                        {{ $t('promotionTemplateDesp') }}
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="col-md-3 col-sm-12 mb-2">
                            </div>
                            <div class="col-md-3 col-sm-12 text-right justify-content-end ">
                                <CButton block style="color: white;" color="success"
                                    @click="$router.push('/promotion/create')">
                                    <div class="pr-3 pl-3">
                                        <div class="row d-flex justify-content-center">
                                            <i class="fi fi-rr-settings mr-1"></i>
                                            {{ $t('selfCustom') }}
                                        </div>
                                    </div>
                                </CButton>
                            </div> -->
                        </div>
                        <hr />
                        <div v-if="isGrabOrderEnabled" class="row mt-4">
                            <div class="col-12 pl-3 pt-2 pb-2">
                                <h3> <img src="/img/grabFood.svg" width="120px"></h3>
                                <span style="color: gray; ">
                                    {{ $t('grabPromotionTemplateDesp') }}
                                </span>
                            </div>
                            <div v-for="promotion in grabPromotions" class="col-sm-12 col-md-4 pb-3">
                                <div class="promotion-card  p-0 position-relative" @click="handleTemplate(promotion)">
                                    <img style="aspect-ratio: 16/9;object-fit: cover;" width="100%"
                                        :src="promotion.details.remoteImagePath || noImage">
                                    <div class="text-left p-2">
                                        <h5 class="">{{ $t(promotion.details.title) }}
                                        </h5>
                                        <div style="color: gray;">
                                            <div class="d-flex align-items-center mb-2">
                                                <i class="fi fi-rr-badge-percent mr-2 mb-0"></i>
                                                {{ $t('promotion') }} : {{ $t(promotion.type) }}
                                            </div>
                                            <div v-if="promotion.condition.type !== 'NO_CONDITION'"
                                                class="d-flex align-items-center mb-2">
                                                <i class="fi fi-rr-comment-question mr-2 mb-0"></i>
                                                {{ $t('conditionHeader') }} : {{ $t(promotion.condition.type) }}
                                            </div>
                                            <div v-else class="d-flex align-items-center mb-2">
                                                <i class="fi fi-rr-comment-question mr-2 mb-0"></i>
                                                {{ $t('conditionHeader') }} : {{ $t('NO_CONDITION') }}
                                            </div>
                                            <div class="d-flex align-items-center mb-2">
                                                <i class="fi fi-rr-star mr-2 mb-0"></i>
                                                {{ $t('support') }} : <img class="ml-1" src="/img/grabFood.svg"
                                                    width="60px">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="row mt-4">
                            <div class="col-12 pl-3 pt-2 pb-2">
                                <h3>{{ $t('templates') }}</h3>
                            </div>
                            <div v-for="promotion in promotions" class="col-sm-12 col-md-4 pb-3">
                                <div class="promotion-card  p-0 position-relative" @click="handleTemplate(promotion)">
                                    <img style="aspect-ratio: 16/9;object-fit: cover;" width="100%"
                                        :src="promotion.details.remoteImagePath || noImage">
                                    <div class="text-left p-2">
                                        <h5 class="">{{ $t(promotion.details.title) }}
                                        </h5>
                                        <div style="color: gray;">
                                            <div class="d-flex align-items-center mb-2">
                                                <i class="fi fi-rr-badge-percent mr-2 mb-0"></i>
                                                {{ $t('promotion') }} : {{ $t(promotion.type) }}
                                            </div>
                                            <div v-if="promotion.condition.type !== 'NO_CONDITION'"
                                                class="d-flex align-items-center mb-2">
                                                <i class="fi fi-rr-comment-question mr-2 mb-0"></i>
                                                {{ $t('conditionHeader') }} : {{ $t(promotion.condition.type) }}
                                            </div>
                                            <div v-else class="d-flex align-items-center mb-2">
                                                <i class="fi fi-rr-comment-question mr-2 mb-0"></i>
                                                {{ $t('conditionHeader') }} : {{ $t('selfCustom') }}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-else>
                        <TemplateForm :template="template" @sendTemplate="resetTemplate" />
                    </div>
                </CCardBody>
            </CCard>

            <CModal :show.sync="templateModal" centered :title="$t('confirmSavePromotionHeader')" color="success"
                header="false">

            </CModal>
        </div>
        <div v-else>
            <access-data></access-data>
        </div>
    </div>
    <div v-else>
        <CCard>
            <CCardBody style="padding-top: 20cap;">
                <div style="width: 100%;height: 100vh;display: flex;justify-content: center;">
                    <CSpinner color="success"></CSpinner>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'
import permis from '@/util/permission'
import 'vue2-datepicker/index.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import moment from 'moment'
import '@/assets/styles/promotion.css'
import grabPromotionsTemplate from '../template/grabPromotions.js'
import promotionsTemplate from '../template/promotions.js'
import TemplateForm from './TemplateForm.vue';
import pos from '@/services/local';

export default {
    components: {
        TemplateForm
    },
    data() {
        return {
            loadingButton: false,
            templateModal: false,
            template: {},
            orderShopSetting: {},
            loading: false
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date', 'permissions']),
        isPermission() {
            // return permis.findPermissionRead('inventory', this.$route.path)
            const shopObjectId = localStorage.getItem('shopObjectId');
            const selectedShop = this.shops.find(shop => shop.objectId === shopObjectId) || null
            return selectedShop?.currentPlan == "multiplePOS" && this.orderShopSetting?.isGrabOrderEnabled
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId;
            },
            set(newValue) {
                return this.$store.dispatch("setShop", newValue);
            },
        },
        uid() {
            return localStorage.getItem('uid')
        },
        grabPromotions() {
            return Object.values(grabPromotionsTemplate)
        },
        promotions() {
            return Object.values(promotionsTemplate)
        },
        noImage() {
            return process.env.VUE_APP_NOIMAGE
        },
        isGrabOrderEnabled() {
            return this.orderShopSetting?.isGrabOrderEnabled || false
        }
    },
    created() {
        this.getOrderShopSetting()
    },
    methods: {
        handleTemplate(template) {
            this.template = template
        },
        resetTemplate() {
            this.template = {}
        },
        getOrderShopSetting() {
            this.loading = true
            const uid = this.uid
            let params = {
                shopObjectId: this.shopObjectId,
            }
            pos({
                method: 'get',
                url: '/api/v2.0/' + uid + '/OrderShopSetting',
                params: params,
                headers: { shopObjectId: this.shopObjectId }
            }).then((res) => {
                this.orderShopSetting = res?.data?.data[0] || null
                this.loading = false
            })

        },
    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$router.push('/promotion')
            }
        },
        startDate(newValue) {
            if (newValue) {
                this.startDate = moment(newValue).startOf('day').toISOString();
            }
        },
        endDate(newValue) {
            if (newValue) {
                this.endDate = moment(newValue).endOf('day').toISOString();
            }
        }
    },
}
</script>
<style></style>