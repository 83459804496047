const promotions = {
    promotion001: {
        type: 'ITEM_DOLLAR_DISCOUNT',
        results: [
            {
                value: null,
                items: [],
                quantityLimit: null
            },
        ],
        condition: {
            type: "NO_CONDITION",
        },
        details: {
            title: "promotion001",
            description: "Apply a fixed dollar discount to specific items. | ให้ส่วนลดเงินสดกับสินค้าที่กำหนด",
            remoteImagePath: "/img/promotion/promotion001.jpg"
        },
    },
    promotion002: {
        type: 'CATEGORY_DOLLAR_DISCOUNT',
        results: [
            {
                value: null,
                categories: [],
                quantityLimit: null
            },
        ],
        condition: {
            type: "NO_CONDITION",
        },
        details: {
            title: "promotion002",
            description: "Apply a fixed dollar discount to items within a specific category. | ให้ส่วนลดเงินสดกับสินค้าภายในหมวดหมู่ที่กำหนด",
            remoteImagePath: "/img/promotion/promotion002.jpg"
        },
    },
    promotion003: {
        type: 'RECEIPT_DOLLAR_DISCOUNT',
        results: [
            {
                value: null,
            },
        ],
        condition: {
            type: "PRICE_RECEIPT_EXCEED",
        },
        details: {
            title: "promotion003",
            description: "Apply a fixed dollar discount to the entire receipt. | ให้ส่วนลดเงินสดกับยอดรวมของใบเสร็จ",
            remoteImagePath: "/img/promotion/promotion003.jpg"
        },
    },
    promotion004: {
        type: 'ITEM_PERCENTAGE_DISCOUNT',
        results: [
            {
                value: null,
                items: [],
                quantityLimit: null,
                cap: null,
            },
        ],
        condition: {
            type: "NO_CONDITION",
        },
        details: {
            title: "promotion004",
            description: "Apply a percentage discount to specific items. | ให้ส่วนลดเปอร์เซ็นต์กับสินค้าที่กำหนด",
            remoteImagePath: "/img/promotion/promotion004.jpg"
        },
    },
    promotion005: {
        type: 'CATEGORY_PERCENTAGE_DISCOUNT',
        results: [
            {
                value: null,
                categories: [],
                quantityLimit: null,
                cap: null,
            },
        ],
        condition: {
            type: "NO_CONDITION",
        },
        details: {
            title: "promotion005",
            description: "Apply a percentage discount to items within a specific category. | ให้ส่วนลดเปอร์เซ็นต์กับสินค้าภายในหมวดหมู่ที่กำหนด",
            remoteImagePath: "/img/promotion/promotion005.jpg"
        },
    },
    promotion006: {
        type: 'RECEIPT_PERCENTAGE_DISCOUNT',
        results: [
            {
                value: null,
                cap: null,
            },
        ],
        condition: {
            type: "PRICE_RECEIPT_EXCEED",
        },
        details: {
            title: "promotion006",
            description: "Apply a percentage discount to the total receipt. | ให้ส่วนลดเปอร์เซ็นต์กับยอดรวมของใบเสร็จ",
            remoteImagePath: "/img/promotion/promotion006.jpg"
        },
    },
};
export default promotions;
